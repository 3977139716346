// assets
import { TruckFast, ShoppingBag, Calculator, ArrowSquareDown, ArrowSquareUp, ShoppingCart } from 'iconsax-react';

// icons
const icons = {
    TruckFast, ShoppingBag, Calculator, ArrowSquareDown, ArrowSquareUp, ShoppingCart
};

let children = [];

children = [
    {
        id: 'analytics',
        title: 'Analytics',
        type: 'item',
        url: '/analytics',
        icon: icons.Calculator,
        breadcrumbs: false
    },
    {
        id: 'product',
        title: 'Raw Material',
        type: 'item',
        url: '/products',
        icon: icons.ShoppingBag,
        breadcrumbs: false
    },
    {
        id: 'packaging-material',
        title: 'Packaging Material',
        type: 'item',
        url: '/packaging-material',
        icon: icons.ShoppingCart,
        breadcrumbs: false
    },
    {
        id: 'vendor',
        title: 'Vendors',
        type: 'item',
        url: '/vendors',
        icon: icons.TruckFast,
        breadcrumbs: false
    },
    {
        id: 'stockIn',
        title: 'Stock In',
        type: 'item',
        url: '/stockIn',
        icon: icons.ArrowSquareDown,
        breadcrumbs: false
    },
    {
        id: 'stockOut',
        title: 'Stock Out',
        type: 'item',
        url: '/stockOut',
        icon: icons.ArrowSquareUp,
        breadcrumbs: false
    },
]
const tabs = {
    id: 'group-tabs',
    title: '',
    icon: icons.tabs,
    type: 'group',
    children
};

export default tabs;
